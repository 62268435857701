.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar{
  /* box-shadow: inset 0 calc(-1 * var(--tblr-navbar-border-width)) 0 0 #C0B8A3; */
  background: #C0B8A3;
}
.navbar-expand-md .nav-item.active:after{
  border-style: 0 var(--tblr-border-style) #C0B8A3;
}
.navbar .navbar-nav .nav-link{
  color: #fff;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page {
  background: #fff !important;
}

@media (max-width: 768px) {
  .page-header>.container-xl>.row {
    display: block !important;
  }

  .order-label span {
    font-size: 12px;
    padding: 3px 8px !important;
  }

  .order-text {
    padding: 5px !important;
  }

  .order-text h3 {
    font-size: 13px;
    line-height: 15px;
  }

  .order-text p {
    font-size: 12px;
  }

  div.datagridtable-responsive {
    overflow-y: scroll;
  }
  td > button.btn{
    margin-bottom: 3px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {

  .nav-item.dropdown:hover>.dropdown-menu,
  .nav-item.dropdown:active>.dropdown-menu,
  .nav-item.dropdown:focus>.dropdown-menu {
    position: absolute;
    display: block !important;
    top: 45px;
    right: 5px;
  }
}

td span.dropdown:hover>button.dropdown-toggle {
  color: var(--tblr-btn-active-color);
  background-color: var(--tblr-btn-active-bg);
  border-color: var(--tblr-btn-active-border-color);
  box-shadow: var(--tblr-btn-active-shadow);
}

td span.dropdown:hover div.dropdown-menu {
  display: block !important;
}

.drop-zone {
  max-width: 200px;
  height: 200px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #dce1e7;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.image-aspect-ratio {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.image-aspect-ratio::before {
  display: block;
  content: "";
}

.image-aspect-ratio-21by9::before {
  padding-top: 42.8571428571%;
}

.image-aspect-ratio-16by9::before {
  padding-top: 56.25%;
}

.image-aspect-ratio-4by3::before {
  padding-top: 75%;
}

.image-aspect-ratio-3by4::before {
  padding-top: 133.33%;
}

.image-aspect-ratio-1by1::before {
  padding-top: 100%;
}

.image-aspect-ratio img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.btn-primary {
  background: #C0B8A3 !important;
  color: #fff;
}

.active>.page-link, .page-link.active{
  background: #C0B8A3 !important;
}